export default class profileSubscription {
    constructor() {
        this.refundBtns = $('.js-refund');
        this.modal = new bootstrap.Modal(document.getElementById('exampleModalToggle'), {
            keyboard: false
        });
        this.csrfToken = $('[name="csrfmiddlewaretoken"]').val();
        this.btnCloseModal = $('.js-btn-close');
        this.btnSendRefund = $('.js-send-refund');
        this.init();

    }


    fillForm(btn) {
        const course = $(btn).attr('data-course');
        const price = $(btn).attr('data-price');
        const course_id = $(btn).attr('data-course_id');
        const purchase_id = $(btn).attr('data-purchase_id');
        $("[name='course_id']").val(course_id);
        $("[name='purchase_id']").val(purchase_id);
        $('#course-title').html(course);
        $('#course-price').html(price);
    }

    clearForm(){
        $("[name='course_id']").val('');
        $("[name='purchase_id']").val('');
        $("[name='reason']").val('');
        $("[name='details']").val('');
        $('#course-title').html('');
        $('#course-price').html('');
    }

    toggleModal() {
        const _self = this;
        this.btnCloseModal.on('click', function (e) {
            _self.modal.toggle();
            _self.clearForm();
        });

        this.refundBtns.on('click', function () {
            _self.fillForm(this);
            _self.modal.toggle();
        })
    }


    getFormData(){
        return {
            'course_id': $('[name="course_id"]').val(),
            'user_id': $('[name="user_id"]').val(),
            'purchase_id': $('[name="purchase_id"]').val(),
            'reason': $('[name="reason"]').val(),
            'details': $('[name="details"]').val(),

        }
    }

    sendRequest() {
        const _self = this;
        this.btnSendRefund.on('click', function () {
            const url = $(this).attr('data-url');
            const payload =_self.getFormData();

            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify(payload),
                'headers': {
                    'X-CSRFToken': _self.csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    const result = data.result;
                    if (data.status) {
                        $('.modal-body').html(
                            `<p class="alert alert-success text-center">Ваша заявка на розгляді.</p>`
                        );
                        setTimeout(function () {
                            window.location.reload();
                        }, 2500)
                    }
                    console.log(data.status);
                })
            console.log(payload, '!!!');
        })
    }


    init() {
        this.toggleModal();
        this.sendRequest();
        console.log('profileSubscription');
    }

}