export default class previewCourse {
    constructor() {
        this.playPreviewVideos = $('.js-play-preview');

        this.modal = new bootstrap.Modal(document.getElementById('exampleModalToggle'), {
            keyboard: false
        })

        this.btnCloseModal = $('.js-btn-close');

        this.init();
    }


    togglePreviewVideo() {
        const _self = this;
        this.btnCloseModal.on('click', function (e) {
            _self.modal.toggle();
            $('.modal-body').html('');
            $('.modal-title').html('');
        })

        this.playPreviewVideos.on('click', function (e) {
            console.log(e);

            const iframe = $(this).attr('data-iframe');
            const title = $(this).attr('data-title');
            console.log($(_self.modal).find('.modal-body'), 'modal!');
            //iframe.style = "height: 100%";
            $('.modal-body').html(iframe);
            $('.modal-title').html(title);
            _self.modal.toggle();
            const videoPreviewWrap = $(this).closest('.js-video-preview');
            // const videoWrap = videoPreviewWrap.find('.js-video-wrap');
            const videoInfo = videoPreviewWrap.find('.js-video-info');
            // videoInfo.find('img');
            // videoWrap.removeClass('hide');
            // videoInfo.addClass('hide');
            // videoWrap.html(iframe);

            // iframe.width = iframe.contentWindow.document.body.scrollWidth;
            // iframe.height = iframe.contentWindow.document.body.scrollHeight;
            // videoPreviewWrap.animate({width: '100%'}, 400);

        })
        console.log('!!!!');
    }


    init() {
        this.togglePreviewVideo();

    }
}